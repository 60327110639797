import { Tenant } from '@/store/modules/app/types'
import { Namespace } from '@/store/types'
import { segmentEventTracking } from '@/utils/tracking'
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

@Component({})
export default class MaxJigsCheck extends Vue {
  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant
  @State('myTenantTotalJigCount', { namespace: Namespace.App })
  public myTenantTotalJigCount!: number

  @Action('loadTenantTotalJigCount', { namespace: Namespace.App })
  public loadTenantTotalJigCount: any

  public async isMaxJigsLimitReached() {
    // Make sure get latest value to avoid error.
    await this.loadTenantTotalJigCount()

    return this.myTenant.Subscription.MaxJigs !== 0 && this.myTenantTotalJigCount >= this.myTenant.Subscription.MaxJigs
  }

  public upgradePlan(myTenantID: number) {
    segmentEventTracking('TenantMaxJigLimit_UpgradePanelClicked', {
      tenantName: this.myTenant.Name,
      tenantId: myTenantID,
    })

    this.$router.push({
      path: '/subscription',
      query: {
        stripeSuccess: this.$route.path,
      },
    })
  }
}
