
import JigTextField from '@/components/input/JigTextField.vue'
import DataTable from '@/components/material/DataTable.vue'
import PromptYesNo from '@/components/modals/PromptYesNo.vue'
import QRCodeModal from '@/components/modals/QRCodeModal.vue'
import ShareModal from '@/components/modals/ShareModal.vue'
import ShareSuccessSnackbar from '@/components/share/ShareSuccessSnackbar.vue'
import ShareableLink from '@/components/share/ShareableLink.vue'
import MaxJigsCheck from '@/mixin/MaxJigsCheck'
import { DataTableHeadings } from '@/modules/library/types'
import { thumbnailURLMap } from '@/plugins/filters'
import { Tenant } from '@/store/modules/app/types'
import { AppConst } from '@/store/modules/constants'
import {
  JigMetaData,
  MyJig,
  Permission,
  TeamJig,
  TenantJigPermissions,
} from '@/store/modules/jig/types'
import { Namespace } from '@/store/types'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { segmentEventTracking } from '@/utils/tracking'
import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

@Component({
  components: {
    'prompt-yes-no': PromptYesNo,
    'prompt-share-modal': ShareModal,
    'jig-text-field': JigTextField,
    'shareable-link': ShareableLink,
    'share-success-snackbar': ShareSuccessSnackbar,
    'qr-code-modal': QRCodeModal,
    'data-table': DataTable,
  },
})

export default class JigsListingVue extends Mixins(MaxJigsCheck) {
  @State('loading', { namespace: Namespace.Utils })
  public loading!: boolean
  @State('myJigs', { namespace: Namespace.Jig })
  public myJigs!: MyJig[]
  @State('teamJigs', { namespace: Namespace.Jig })
  public teamJigs!: JigMetaData[]
  @State('jigDetailPermissions', { namespace: Namespace.Jig })
  public jigDetailPermissions!: Permission[]
  @State('myJigsItemsPerPage', { namespace: Namespace.Utils })
  public myJigsItemsPerPage!: number
  @State('teamJigsItemsPerPage', { namespace: Namespace.Utils })
  public teamJigsItemsPerPage!: number
  @State('uid', { namespace: Namespace.App })
  public uid!: number

  @Action('deleteJigById', { namespace: Namespace.Jig })
  public deleteJigById: any
  @Action('copyJigById', { namespace: Namespace.Jig })
  public copyJigById: any
  @Action('loadMyJigs', { namespace: Namespace.Jig })
  public loadMyJigs: any
  @Action('loadTeamJigs', { namespace: Namespace.Jig })
  public loadTeamJigs: any
  @Action('loadJigPermissions', { namespace: Namespace.Jig })
  public loadJigPermissions: any
  @Action('updateJigPermission', { namespace: Namespace.Jig })
  public updateJigPermission: any
  @Action('deleteJigPermission', { namespace: Namespace.Jig })
  public deleteJigPermission: any
  @Action('requestJigDeeplinkURL', { namespace: Namespace.Jig })
  public requestJigDeeplinkURL: any

  public $refs!: Vue['$refs'] & {
    deleteJigPromptYesNo: PromptYesNo,
    copyJigPromptYesNo: PromptYesNo,
    jigLimitPromptYesNo: PromptYesNo,
    sharePrompt: ShareModal,
    jigListing: typeof DataTable,
  }

  public selectedJigId!: string | ''
  public selectedJigName: string = ''
  public selectedJigFormatVersion: number = 0

  public newCopyJigName: string = ''
  public selectedJigVisibility: string = ''

  public visibilityPublic: string = AppConst.JigVisibilities.Public

  private searchCriteria: string = ''
  private permissionsDirty: boolean = false
  private jigListHeadings: DataTableHeadings = {}
  private jigListShareHeaders: DataTableHeadings = {
    preview: {
      copy: 'Preview',
      sort: false,
    },
    name: {
      copy: 'Name',
      sort: true,
      isAsc: true,
    },
    dateUpdated: {
      copy: 'Updated',
      sort: true,
      isAsc: false,
    },
    visibility: {
      copy: 'Visibility',
      sort: true,
      isAsc: true,
      sortFunc: (item: TeamJig) => (this.isUnlistedJig(item.visibility as string) ? 'Unlisted' : item.visibility),
    },
    author: {
      copy: 'Author',
      sort: true,
      isAsc: true,
      sortFunc: (item: TeamJig) => (item.author === this.$auth0.user.email ? 'You' : item.author),
    },
  }
  private teamJigsOnlyHeaders: DataTableHeadings = {
    teamVisibility: {
      copy: 'Team visibility',
      sort: true,
      isAsc: true,
      sortFunc: (item: TeamJig) => (this.getTeamVisibilities(item.Tenants as TenantJigPermissions[])),
    },
  }
  private jigListActionHeader: any = {
    action: {
      copy: 'Actions',
      sort: false,
    },
  }
  // private currentPage = 1
  private jigListItemsPerPage = [10, 50, 100, -1]
  private sourceJigList: TeamJig[] = []
  private jigList: TeamJig[] = []
  private myTenantID: number = -1
  private teamSharePermissionsViewValue: string = AppConst.SharePermission.teamPermissionView
  private teamSharePermissionsReshareValue: string = AppConst.SharePermission.teamPermissionReshare
  private teamVisibilities: any = {
    [AppConst.SharePermission.teamPermissionView]: 'View',
    [AppConst.SharePermission.teamPermissionReshare]: 'Share',
  }

  private selectAllRows: boolean = false
  // V-checkbox binding with deep structured object data seems can't clear the value properly. When passing in simply structured data value can be reset successifully. Hence spliting out the model value and the select jig full data.
  private rowsSelected: any[] = []
  private rowsSelectedJigs: any[] = []

  private clipboardSnackbarVisible: boolean = false
  private latestClipboardEntry: string = ''
  private latestClipboardTitle: string = ''
  private isClipboardCopySuccess: boolean = false
  private showTeamShareTooltip: any = {}

  private get isTeamJigView(): boolean {
    if (this.$route.meta === undefined) {
      return false
    }
    return this.$route.meta.teamJigView || false
  }

  private get isSuperUserOrJigStaff(): boolean {
    return TenantHelpers.IsSuperUserOrJigStaff()
  }

  private get eventTracking(): any {
    return {
      action: this.isTeamJigView ? 'TeamJigList' : 'JigList',
    }
  }

  private async loadSourceJigs() {
    if (this.isTeamJigView) {
      // TeamJigs view
      await this.loadTeamJigs()
      this.jigList = this.teamJigs.map((jig) => {
        const newJig = {
          author: jig.Author,
          categories: jig.Categories,
          copyableBy: jig.CopyableBy,
          dateCreated: jig.DateCreated,
          datePublished: jig.DatePublished,
          dateUpdated: jig.DateUpdated,
          deeplinkURL: jig.DeeplinkURL,
          deeplinkURLRequesting: jig.DeeplinkURLRequesting,
          description: jig.ProjectDescription,
          formatVersion: jig.FormatVersion,
          globalScale: jig.GlobalScale,
          id: jig.Id,
          movoEnabled: jig.MovoEnabled,
          name: jig.ProjectName,
          ownerTenantID: jig.OwnerTenantID,
          password: jig.Password,
          passwordDateCreated: jig.PasswordDateCreated,
          requestUserPermission: jig.RequestUserPermission,
          tags: jig.Tags,
          Tenants: jig.Tenants,
          thumbnailURL: jig.ThumbnailURL,
          type: jig.Type,
          uid: jig.Uid,
          version: jig.Version,
          views: jig.Views,
          visibility: jig.ProjectVisibility,
          votes: jig.votes,
        }

        this.showTeamShareTooltip[jig.Id] = false

        return newJig
      })
    } else {
      // MyJigs view
      await this.loadMyJigs()
      this.jigList = this.myJigs
    }

    this.sourceJigList = this.jigList

    if (this.myTenant.ID !== TenantHelpers.InvalidTenantID) {
      this.myTenantID = this.myTenant.ID
    }
  }

  @Watch('myTenant')
  private async onMyTenantChanged(value: Tenant) {
    this.myTenantID = value.ID
    await this.initJigsSourceData()
  }

  @Watch('$route', { immediate: true })
  private async onRouteChange(newRoute: any) {
    // As route change watcher happens before created hook, move initialise functions to here.
    // We need to reload jigs as myJigs and teamJigs are from different API Endpoint and also they display different headings/values
    await this.initJigsSourceData()
  }

  private async initJigsSourceData() {
    this.selectedJigId = ''

    this.updateTableHeaders()

    await this.loadSourceJigs()
    // The selection value of the checkboxes is bound to both a :v-model of jigs[] (myJigs) and also a :value of an individual jig existing in that array.
    // If the jig load happens after the state is set once and updated, there seems to be a problem with re-loading the myJigs, which will check all the boxes.
    // So we uncheck them here after loading.
    if (this.$refs.jigListing) {
      (this.$refs.jigListing as any).unselectAllItems()
    }
    this.selectAllRows = false
    this.rowsSelected = []
    this.rowsSelectedJigs = []
  }

  private async deleteJig() {
    if (await this.deleteJigById({ jigId: this.selectedJigId, jigFormatVersion: this.selectedJigFormatVersion })) {
      segmentEventTracking(
        'JigDeleted',
        {
          ...this.eventTracking,
          jigId: this.selectedJigId,
          jigName: this.selectedJigName,
        }
      )
      await this.loadSourceJigs()
    }
  }

  private selectAllRowsChanged(payload: any) {
    this.selectAllRows = payload.selectedAllRows
    this.rowsSelected = payload.rowsSelected
    this.rowsSelectedJigs = payload.rowsSelectedItems
  }

  private onJigSelectChange(payload: any) {
    this.rowsSelected = payload.rowsSelected
    this.rowsSelectedJigs = payload.rowsSelectedItems
  }

  private updateTableHeaders() {
    this.jigListHeadings = {
      ...this.jigListShareHeaders,
    }

    if (this.isTeamJigView) {
      this.jigListHeadings = {
        ...this.jigListHeadings,
        ...this.teamJigsOnlyHeaders,
      }
    }

    this.jigListHeadings = {
      ...this.jigListHeadings,
      ...this.jigListActionHeader,
    }
  }

  private isOwner(uid: any) {
    return uid === this.$auth0.uid
  }

  private isCopyAllowed(uid: any, copyableBy: string) {
    // Jig can be duplicated if:
    // 1. user is Jig owner
    // 2. Jig is set to CopyableByAnyone
    // TODO: 3. Jig is set to CopyableByTeam and user is part of the team (OwnerTenantID is not available yet on this page)
    // 4. Jig is set to Copyable by Team/Anyone and user is a superuser

    return this.isOwner(uid) || copyableBy === AppConst.JigCopyable.Anyone || (this.isSuperUserOrJigStaff && copyableBy === AppConst.JigCopyable.Tenant)
  }

  private inviteSucceeded(payload: any) {
    segmentEventTracking(
      'JigShared',
      {
        ...this.eventTracking,
        jigId: this.selectedJigId,
        jigName: this.selectedJigName,
        editorEmail: payload.editorEmail,
        viewersEmails: payload.viewersEmails.join(',')
      }
    )
  }

  private showShareSuccessMsg(payload: any) {
    this.isClipboardCopySuccess = payload.isClipboardCopySuccess
    this.latestClipboardTitle = payload.latestClipboardTitle
    this.latestClipboardEntry = payload.latestClipboardEntry
    this.clipboardSnackbarVisible = payload.clipboardSnackbarVisible
  }

  private snackbarClose(payload: boolean) {
    this.clipboardSnackbarVisible = payload
  }

  private showDeleteJigPrompt(jigId: string, jigName: string, jigFormatVersion: number) {
    this.selectedJigId = jigId
    this.selectedJigName = jigName
    this.selectedJigFormatVersion = jigFormatVersion
    this.$refs.deleteJigPromptYesNo.ShowPrompt()
  }

  private showInviteNewUsersPrompt() {
    if (this.rowsSelected.length === 0) {
      return
    }

    const jigCount = this.rowsSelected.length
    this.selectedJigName = this.rowsSelectedJigs[0].name
    if (jigCount === 2) {
      this.selectedJigName = (this.selectedJigName + ' + 1 other Jig')
    } else {
      this.selectedJigName = (this.selectedJigName + ' + ' + (jigCount - 1) + ' other Jigs')
    }

    this.$refs.sharePrompt.ShowPrompt(this.rowsSelectedJigs)
  }

  private showJigPermissionsPrompt(jig: TeamJig) {
    const jigId = jig.id
    const jigName = jig.name
    const jigOwnerUid = jig.uid
    this.selectedJigName = jigName
    this.selectedJigId = jigId
    const jigIndex = this.jigList.findIndex(j => j.id === jigId)
    let canEditJig = false
    if (jigIndex >= 0) {
      canEditJig = this.canEditJig(this.jigList[jigIndex])
    }

    if (!canEditJig) {
      canEditJig = jigOwnerUid === this.$auth0.uid
    }

    this.$refs.sharePrompt.ShowPrompt([jig])
  }

  onUpdateJigShareConfig(payload: any) {
    this.permissionsDirty = payload.permissionsDirty
  }

  private isUnlistedJig(visibility: string) {
    return visibility === AppConst.JigVisibilities.Link
  }

  private onSharePromptClose(payload: any) {
    if (payload.updateResults === false) {
      this.permissionsDirty = true
    }

    this.onPermissionPromptClosed()
  }

  private onPermissionPromptClosed() {
    // deselect all selected jigs
    if (this.$refs.jigListing) {
      (this.$refs.jigListing as any).unselectAllItems()
    }
    this.selectAllRows = false
    this.rowsSelected = []
    this.rowsSelectedJigs = []
    this.loadMyJigsIfPermissionsDirty()
  }

  private async loadMyJigsIfPermissionsDirty() {
    if (this.permissionsDirty) {
      this.permissionsDirty = false
      await this.loadSourceJigs()
    }
  }

  private async copyJig() {
    if (await this.copyJigById({
      jigId: this.selectedJigId,
      newName: this.newCopyJigName,
      tenantId: this.myTenantID,
    })) {
      segmentEventTracking(
        'JigCopied',
        {
          ...this.eventTracking,
          jigId: this.selectedJigId,
          jigName: this.newCopyJigName,
          sourceJigName: this.selectedJigName,
        }
      )
      await this.loadSourceJigs()
    }
  }

  private async showCopyJigPrompt(jigId: string, jigName: string, visibility: string) {
    const isJigLimitReached = await this.isMaxJigsLimitReached()

    if (isJigLimitReached) {
      segmentEventTracking('TenantMaxJigLimit_FeatureGateShown', {
        tenantName: this.myTenant.Name,
        tenantId: this.myTenantID,
      })
      this.$refs.jigLimitPromptYesNo.ShowPrompt()
    } else {
      this.selectedJigId = jigId
      this.selectedJigName = jigName
      this.newCopyJigName = this.selectedJigName + ' copy'
      this.selectedJigVisibility = visibility
      this.$refs.copyJigPromptYesNo.ShowPrompt()
    }
  }

  private getViewLabel(jig: TeamJig): string {
    return this.isViewOnlyJig(jig) ? 'View' : 'Edit'
  }

  private isViewOnlyJig(jig: TeamJig) {
    if (this.isCRUDEmpty(jig)) {
      return false
    }

    return jig.requestUserPermission.CRUD.R && !jig.requestUserPermission.CRUD.U
  }

  private canEditJig(jig: TeamJig) {
    if (this.isCRUDEmpty(jig)) {
      return false
    }

    return jig.requestUserPermission.CRUD.U == true
  }

  private isCRUDEmpty(jig: TeamJig) {
    return !jig || !jig.requestUserPermission || !jig.requestUserPermission.CRUD || !Object.keys(jig.requestUserPermission.CRUD).length
  }

  private isJigOwnedByUser(jig: TeamJig) {
    if (jig === null) {
      return false
    }

    return this.isOwner(jig.uid)
  }

  private isShareDisabled(jig: TeamJig) {
    if (this.isOwner(jig.uid) || !this.isTeamJigView || this.canEditJig(jig)) {
      // if you are the jig owner, or jig editor, or you are on "My Jigs" page, you can share regardless.
      return false
    } else {
      // If you are not the owner and you are on team share page, you only can share if `shareable-readonly` permission is available.
      return !(jig.Tenants && this.checkTeamShareSetting(jig.Tenants, this.teamSharePermissionsReshareValue))
    }
  }

  private getTeamSharePermissions(tenants: TenantJigPermissions[]) {
    if (!tenants || tenants.length === 0) {
      return null
    }

    return tenants.find((tenant) => tenant.TenantID === this.myTenantID)
  }

  private checkTeamShareSetting(tenants: TenantJigPermissions[], permission: string) {
    if (!this.isTeamJigView) {
      return true
    } else if (!tenants || tenants.length === 0) {
      return false
    }

    const teamSharePermissions = this.getTeamSharePermissions(tenants)

    return teamSharePermissions ? teamSharePermissions.Permissions.includes(permission) : false
  }

  private getTeamVisibilities(tenants: TenantJigPermissions[]) {
    const teamSharePermissions = this.getTeamSharePermissions(tenants)

    return teamSharePermissions ? teamSharePermissions.Permissions.map(permission => this.teamVisibilities[permission]).join(', ') : ''
  }

  private toggleTeamShareTooltip(status: boolean, id: string) {
    // Make sure can only toggle tooltip on hover when button is disabled (jig is not set as `unlisted`)
    this.showTeamShareTooltip = {
      ...this.showTeamShareTooltip,
      [id]: status,
    }
  }

  private get itemsPerPage(): number {
    return this.isTeamJigView ? this.$store.state.utils.teamJigsItemsPerPage : this.$store.state.utils.myJigsItemsPerPage
  }

  private onItemsPerPageChange(newItemsPerPage: number) {
    // This is a 2 way binding
    if (this.isTeamJigView) {
      this.$store.state.utils.teamJigsItemsPerPage = newItemsPerPage
    } else {
      this.$store.state.utils.myJigsItemsPerPage = newItemsPerPage
    }
  }

  private searchJigs() {
    this.jigList = this.sourceJigList.filter((jig: TeamJig) => {
      let isAMatch = false

      if (this.searchCriteria) {
        const jigName = jig.name.toLowerCase()
        const jigDescription = jig.description.toLowerCase()
        const keyword = this.searchCriteria.toLowerCase()

        if (jigName.includes(keyword) || jigDescription.includes(keyword)) {
          isAMatch = true
        } else if (jig.tags.length && jig.tags.find(tag => tag.toLowerCase().includes(keyword))) {
          isAMatch = true
        }
      } else {
        isAMatch = true
      }

      return isAMatch
    })
  }

  private resetJigList() {
    this.jigList = this.sourceJigList
  }
}
